import BaseModel from '~/models/BaseModel'
import TenantEntity from '~/models/TenantEntity'
import CurrentTier from '~/models/CurrentTier'

export const Features = ['award', 'exercise', 'buyback', 'termination', 'dividendPayout'] as const
export type Feature = (typeof Features)[number]

export default class Tenant extends BaseModel {
  public static mapping = {
    tenantEntity: () => TenantEntity,
    currentTier: () => CurrentTier
  }

  public id!: number
  public uid!: string
  public tenantEntity!: TenantEntity
  public isHrisActive!: boolean
  public isDemo!: boolean
  public isSelfSign!: boolean
  public currentTier!: CurrentTier | null

  public features!: Feature[]
  public guideLink?: string
  public dryRunEmail?: string

  public createdAt!: string
  public deletedAt?: string

  public get currency(): string {
    return this.tenantEntity.currencyCode || 'EUR'
  }

  public get isActive(): boolean {
    return !this.deletedAt
  }
}

import { useTenant } from '~/hooks/tenant'
import { useUser } from '~/hooks/user'
import { useMemo } from 'react'
import { DateTime, Interval } from 'ts-luxon'
import { firstLetterUppercase } from '~/utils/formatters'

const replaceSymbol = (formatted: string) => {
  return formatted.replace('EUR', '€').replace('CZK', 'Kč')
}

const formatDateToRelativeFunction = (date: string, locale: string) => {
  const time = DateTime.fromISO(date).setLocale(locale)
  const diff = Interval.fromDateTimes(time, DateTime.local().setLocale(locale)).toDuration()
  const diffInSeconds = Math.floor(diff.as('seconds'))
  const diffInHours = Math.floor(diff.as('hours'))

  if (diffInSeconds <= 60) {
    return 'Just now'
  } else if (diffInHours < 24) {
    return time.toRelative()
  } else if (diffInHours < 48) {
    return 'Yesterday'
  } else {
    return time.setLocale(locale).toLocaleString(DateTime.DATE_FULL)
  }
}

const createAmountFormatter = (lang: string, currency: string) => {
  return Intl.NumberFormat(lang, {
    notation: 'standard',
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currency
  })
}

const createAmountPreciseFormatter = (lang: string, currency: string) => {
  return Intl.NumberFormat(lang, {
    notation: 'standard',
    maximumFractionDigits: 6,
    style: 'currency',
    currency: currency
  })
}

const createAmountCompactFormatter = (lang: string, currency: string) => {
  return Intl.NumberFormat(lang, {
    notation: 'compact',
    style: 'currency',
    currency: currency
  })
}

export const useFormatters = () => {
  const { tenant } = useTenant()
  const { user } = useUser()
  const lang = user?.lang || 'en'

  const amountFormatter = useMemo(
    () => createAmountFormatter(lang, tenant.currency),
    [tenant.currency, lang]
  )

  const amountPreciseFormatter = useMemo(
    () => createAmountPreciseFormatter(lang, tenant.currency),
    [tenant.currency, lang]
  )

  const amountCompactFormatter = useMemo(
    () => createAmountCompactFormatter(lang, tenant.currency),
    [tenant.currency, lang]
  )

  const percentFormatter = useMemo(
    () =>
      Intl.NumberFormat(lang, {
        style: 'percent',
        maximumFractionDigits: 3
      }),
    [lang]
  )

  const compactFormatter = useMemo(() => Intl.NumberFormat(lang, { notation: 'compact' }), [lang])
  const normalNumberFormatter = useMemo(
    () =>
      Intl.NumberFormat(lang, {
        notation: 'standard',
        maximumFractionDigits: 2
      }),
    [lang]
  )

  const currencySymbol = useMemo(
    () => replaceSymbol(amountFormatter.formatToParts(0)[0].value),
    [amountFormatter]
  )

  const formatAmount = (amount: number, overrideCurrency?: string) => {
    const formatter = overrideCurrency
      ? createAmountFormatter(lang, overrideCurrency)
      : amountFormatter
    return replaceSymbol(formatter.format(amount))
  }

  const formatAmountCompact = (amount: number, overrideCurrency?: string) => {
    const formatter = overrideCurrency
      ? createAmountCompactFormatter(lang, overrideCurrency)
      : amountCompactFormatter
    return replaceSymbol(formatter.format(amount))
  }

  const formatAmountPrecise = (amount: number) =>
    replaceSymbol(amountPreciseFormatter.format(amount ?? 0))

  const formatEURAmount = (amount: number) => {
    const amountEURFormatter = Intl.NumberFormat(lang, {
      notation: 'standard',
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'EUR'
    })

    return amountEURFormatter.format(amount ?? 0)
  }

  const formatNormalNumber = (number: number) => normalNumberFormatter.format(number)

  const formatCompact = (amount: number) => compactFormatter.format(amount)

  const formatDate = (value: string | DateTime, format: object = DateTime.DATE_FULL) => {
    const date: DateTime = typeof value === 'string' ? DateTime.fromISO(value) : value
    return date ? date.setLocale(lang).toLocaleString(format) : date
  }

  const formatDateToRelative = (date: string) => formatDateToRelativeFunction(date, lang)

  const formatPercent = (value: number) => percentFormatter.format(value)

  const formatDuration = (start: string | DateTime | null, end: string | DateTime | null) => {
    let result: string | null = null
    if (start && end) {
      const startDate = typeof start === 'string' ? DateTime.fromISO(start) : start
      const endDate = typeof end === 'string' ? DateTime.fromISO(end) : end
      const diff = endDate.diff(startDate, ['months', 'days']).toObject()
      result = ''
      if (diff.months) {
        result += diff.months + ' month' + (diff.months !== 1 ? 's' : '')
      }
      const days = Math.round(diff.days ?? 0)
      if (days || !diff.months) {
        result += (result !== '' ? ', ' : '') + days + ' day' + (days !== 1 ? 's' : '')
      }
    }
    return result
  }

  const codeToLabel = (s: string | null): string => {
    return firstLetterUppercase(s ?? '').replace(/_/g, ' ')
  }

  const formatBoolean = (value: boolean | null | undefined) => {
    return value === true ? 'yes' : value === false ? 'no' : ''
  }

  const formatBytes = (bytes: number, decimals = 1) => {
    if (!+bytes) {
      return '0 Bytes'
    }

    const k = 1000
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`
  }

  return {
    currency: tenant.currency,
    currencySymbol,
    lang,
    formatAmount,
    formatEURAmount,
    formatAmountCompact,
    formatAmountPrecise,
    formatNormalNumber,
    formatCompact,
    formatDate,
    formatDateToRelative,
    formatPercent,
    formatDuration,
    codeToLabel,
    formatBoolean,
    formatBytes
  }
}

export type Formatters = ReturnType<typeof useFormatters>
